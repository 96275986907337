import React, { FunctionComponent } from "react"
import styles from "./Footer.module.css"
import { Link } from "gatsby"
import BreezyLogo from "../images/breezy-logo.svg"
// import logo from '../images/Breezy_logo.png'
// import logo2x from '../images/Breezy_logo@2x.png'
// import Img from 'gatsby-image'

const logo = '../images/Breezy_logo.png'
const logo2x = '../images/Breezy_logo@2x.png'

const Footer: FunctionComponent = ({ children }) => (
  // <footer className={`${styles.root}`}>
  //   <section className="font-sans mb-12 text-1 text-center">
  //     <Link className="hover:underline" to="/blog">Learn</Link>
  //     <span className={`${styles.separator} bg-white mx-3`} />
  //     <Link className="hover:underline" to="/blog/strategic-partnership-stats">Partnership statistics</Link>
  //     <span className={`${styles.separator} bg-white mx-3`} />
  //     <Link className="hover:underline" to="/blog/types-of-strategic-partnerships">Types of strategic partnerships</Link>
  //     <br/><br/>
  //     <Link className="hover:underline" to="/customer-terms">Customer terms of service</Link>
  //     <span className={`${styles.separator} bg-white mx-3`} />
  //     <Link className="hover:underline" to="/website-terms">Website terms of use</Link>
  //     <span className={`${styles.separator} bg-white mx-3`} />
  //     <Link className="hover:underline" to="/privacy">Privacy Notices</Link>
  //     <span className={`${styles.separator} bg-white mx-3`} />
  //     <Link className="hover:underline" to="/hiring">We are hiring</Link>
  //   </section>
  //   <section className="font-sans mb-12 text-4 text-center">
  //     Making your partnership discovery a breeeeze
  //   </section>
  //   <section className="flex flex-wrap items-center justify-center text-center">
  //     <address className="mb-4 not-italic text-1 w-full">
  //       Address to 34B George Street, London
  //     </address>
  //
  //     <small className="text-1">
  //       &copy; Partnered Ltd {(new Date().getFullYear())}. All rights reserved.<br/>Breezy is the trading name of Partnered Ltd. Registered in England 11690208.
  //     </small>
  //   </section>
    //{/*<Img src={'../images/Breezy_logo@2x.png'} alt={'Breezy logo'}  />*/}
      // <Link className="flex flex-row flex-no-wrap items-center justify-center mt-8" to="/">
      //   <div className="mr-2">
      //     <img src={logo} alt="Breezy logo" srcSet={`${logo} 1x, ${logo2x} 2x`} sizes="" width={50} height={60}/>
      //   </div>
      //   <span className="font-black font-sans text-7 ml-4">Breezy</span>
      // </Link>
  // </footer>

  <footer className={`${styles.root}`}>
      <div className="grid grid-cols-1 md:grid-cols-3 text-3 gap-3 container mx-auto">
        <div className="md:col-span-3">
            <Link className="flex flex-row flex-no-wrap items-center mb-4 md:mb-8" to="/">
                <div className="mr-2">
                    <img src={logo} alt="Breezy logo" srcSet={`${logo} 1x, ${logo2x} 2x`} className="h-8 md:h-12" sizes=""/>
                </div>
                <span className="font-black font-sans text-4 md:text-6 ml-3">Breezy</span>
            </Link>
        </div>

        <div className="pb-6">
            <p className="font-serif font-bold text-4 md:text-5 ">Making your partnership discovery a&nbsp;breeeeze</p>
        </div>
        <hr className="md:hidden"/>
        <nav role="navigation" className="">
            <h2 className="text-3 font-sans font-bold mb-0">Explore</h2>
            <ul className="leading-10">
                <li><Link to="/blog">Learn</Link></li>
                <li><Link to="/blog/strategic-partnership-stats">Partnership statistics</Link></li>
                <li><Link to="/blog/types-of-strategic-partnerships">Types of strategic partnerships</Link></li>
            </ul>
        </nav>
        <hr className="md:hidden"/>
        <nav className="">
            <h2 className="text-3 font-sans font-bold mb-0">Comparisons</h2>
            <ul className="leading-10">
                <li><Link to="/grovia-alternative">Breezy VS Grovia</Link></li>
                <li><Link to="/semrush-alternative">Breezy VS Semrush</Link></li>
            </ul>
        </nav>
        <hr className="md:hidden"/>
        <div className="hidden md:block col-span-3 h-12" />
        <nav className="md:order-3">
            <h2 className="hidden">Additional links</h2>
            <ul className="leading-10">
                <li><Link to="/hiring">We are hiring</Link></li>
            </ul>
        </nav>
        <hr className="md:hidden"/>
        <nav role="navigation" className="md:order-2">
            <h2 className="hidden">Legal information</h2>
            <ul className="leading-10">
                <li><Link to="/customer-terms">Terms of use</Link></li>
                <li><Link to="/website-terms">Website terms</Link></li>
                <li><Link to="/privacy">Privacy</Link></li>
            </ul>
        </nav>
        <hr className="md:hidden mb-4"/>
        <div role="contentinfo" className="md:order-1">
            <small>© Partnered Ltd 2023.</small> <br/><br/>
            <address><small>34B George Street, London, W1U7DP</small></address>
            <small>Breezy is the trading name of Partnered Ltd.<br/>Registered in England 11690208.</small>
        </div>
      </div>
  </footer>
)

export default Footer
