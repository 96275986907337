import { GatsbyImageProps } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { useQueryParam } from "gatsby-query-params"
import useSiteMetadata from "../hooks/useSiteMetadata"

interface Props {
  description?: string
  image?: string
  pathname?: string
  title: string
}

interface Metadata {
  canonical: string
  description: string
  image: string
  imageHeight: number
  imageWidth: number
  imageAlt: string
  title: string
  url: string
}

function buildMetadata(props: Props): Metadata {
  const metadata = useSiteMetadata()

  return {
    canonical: props.pathname ? `${metadata.siteUrl}${props.pathname}` : "",
    description: props.description ?? metadata.description,
    // image: props.image ? `${metadata.siteUrl}${props.image}` : "",
    image: `${metadata.siteUrl}/images/ogimage.png`,
    imageHeight: 1200,
    imageWidth: 1200,
    imageAlt: "Breezy logo",
    title: `${props.title}`,
    url: metadata.siteUrl,
  }
}

const Seo: FunctionComponent<Props> = props => {
  const metadata = buildMetadata(props)
  const disablePopovers = useQueryParam("ifr", undefined)

  let helmetMeta = [
    { name: "description", content: metadata.description },
    { property: "og:title", content: metadata.title },
    { property: "og:description", content: metadata.description },
    { property: "og:image", content: metadata.image },
    { property: "og:image:secure_url", content: metadata.image },
    { property: "og:image:width", content: metadata.imageWidth },
    { property: "og:image:height", content: metadata.imageHeight },
    { property: "og:url", content: metadata.url },
    { property: "twitter:title", content: metadata.title },
    { property: "twitter:card", content: "summary" },
    { property: "twitter:description", content: metadata.description },
  ]

  return (
    <Helmet
      bodyAttributes={!!disablePopovers && { class: "br-static" }}
      htmlAttributes={{ lang: "en" }}
      link={
        metadata.canonical
          ? [{ rel: "canonical", href: metadata.canonical }]
          : []
      }
      title={metadata.title}
      meta={helmetMeta.filter(item => !!item.content)}
      script={[
        {
          type: "application/ld+json",
          innerHTML:
            "{\n" +
            '          "@context": "https://schema.org",\n' +
            '          "@type": "Corporation",\n' +
            '          "name": "Breezy",\n' +
            '          "url": "https://breezy.io",\n' +
            '          "logo": "https://breezy.io/images/ogimage.png",\n' +
            '          "alternateName": "Partnered Ltd"\n' +
            "        }",
        },
        {
          id: "vtag-ai-js",
          async: true,
          src: "https://r2.leadsy.ai/tag.js",
          "data-pid": "uDAaGBZ1x53wDmtd",
          "data-version": "062024",
        },
      ]}
    />
  )
}

export default Seo
