import React, { FunctionComponent, useState } from "react"
import { Link } from "gatsby"
import styles from "./Header.module.css"
import BreezyLogo from "./../images/breezy-logo.svg"
import Login from "./icons/Login"

const Header: FunctionComponent = () => {
  const [active, setActive] = useState(false)
  return (
    <header id="br-header"
      className={`${active ? styles.menu__opened : "px-6"} bg-white fixed flex flex-no-wrap items-center justify-between xmd:px-6 py-4 shadow-+md w-full z-40`}>
      <div
        className={`${active ? "border-b border-green-100 pb-4 px-6" : ""} xmd:p-0 flex flex-no-wrap items-center justify-between w-full xmd:w-auto`}>
        <Link className="flex flex-row flex-no-wrap items-center" to="/">
          <div className="mr-2">
            <BreezyLogo
              className="origin-center"
              style={{
                height: "32px",
                transform: "rotate(10deg)",
                width: "27px"
              }}
            />
          </div>
          <span className="font-black font-sans text-4 xmd:text-5">Breezy</span>
        </Link>
        <button className="xmd:hidden text-gray-500 w-10 h-10 relative focus:outline-none bg-white"
                onClick={() => setActive(!active)}>
          <span className="sr-only">Open main menu</span>
          <div className={`${styles.menuBtn} block w-5 absolute transform -translate-x-1/2 -translate-y-1/2`}>
            <span aria-hidden="true"
                  className={`${active ? "rotate-45" : "-translate-y-2"} ${styles.menuLine} block absolute w-5 bg-black transform transition duration-300 ease-in-out`}></span>
            <span aria-hidden="true"
                  className={`${active ? "opacity-0" : ""} ${styles.menuLine} block absolute w-5 bg-black transform transition duration-300 ease-in-out`}></span>
            <span aria-hidden="true"
                  className={`${active ? "-rotate-45 w-5" : "translate-y-2"} ${styles.menuLine} block absolute right-0 w-3 bg-black transform  transition duration-300 ease-in-out`}></span>
          </div>
        </button>
      </div>
      <nav
        className={`${styles.menu} text-center xmd:text-left mt-10 xmd:m-0  xmd:flex xmd:flex-no-wrap font-black font-sans items-center  text-5 xmd:text-3 lg:text-3 xl:text-4 xxl:text-5`}>
        <Link className="block xmd:inline-block xmd:-mr-2 p-5 xmd:p-4 hover:underline" to="/how-it-works">
          How it works
        </Link>
        <Link className="block xmd:inline-block xmd:-mr-2 p-5 xmd:p-4 hover:underline" to="/about-us">
          About us
        </Link>
        <Link className="block xmd:inline-block xmd:-mr-2 p-5 xmd:p-4 hover:underline" to="/pricing">
          Pricing
        </Link>
        <Link className="block xmd:inline-block xmd:-mr-2 p-5 xmd:p-4 hover:underline" to="/blog">
          Blog
        </Link>
          <a
            className="block xmd:inline-block xmd:mr-2 p-5 xmd:p-4 hover:underline text-blue flex items-center justify-center"
            href="/signin"
          >
            Log in <Login className="inline-block relative" style={{top: -2}} title="Door icon" />
          </a>
            <a
              className=" hover:bg-blue cursor-pointer duration-500 font-bold inline-block px-8 py-2 xs:mt-4 xmd:mt-0 rounded-full  lg:text-2 xl:text-3 xxl:text-4 text-white
       bg-black hover:shadow-lg"
              href="/book-a-demo"
            >
              Book a demo
            </a>
      </nav>
    </header>
  )
}

export default Header
