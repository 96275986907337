const DAY = 24 * 60 * 60 * 1000

const serialize = (obj) => {
  let str = []
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }
  return str.join('&')
}

const getQueries = (queryString = window.location.search) => {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    if (decodeURIComponent(pair[0])) {
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
  }
  return query
}

const setCookie = (name, value, days = 9999) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * DAY))
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''};expires=${expires};path=/;samesite=none;secure`
}

const saveAllQueries = () => {
  const queries = getQueries()

  for (let key in queries) {
    if (queries.hasOwnProperty(key)) {
      setCookie(key, queries[key])
    }
  }
}

const getAllCookies = () => {
  return document.cookie.split(';').reduce((cookies, cookie) => {
    const [ name, value ] = cookie.split('=').map(c => c.trim())
    cookies[name] = value
    return cookies
  }, {})
}

const linkHasMacros = url => url ? url.match(/[?|&]([a-zA-Z0-9_.-]+)={[a-zA-Z0-9_.-]+}/g) : null

const replaceMacrosKey = (search, macros, queries) => {
  const matched = macros.match(/{([a-zA-Z0-9_.-]+)}/g)
  if (matched) {
    const key = matched[0].replace('{', '').replace('}', '')
    let toReplace = ''
    if (queries.hasOwnProperty(key)) {
      toReplace = macros.replace(`{${key}}`, queries[key])
    }
    return search.replace(macros, toReplace)
  }
  return search
}

export const startForwardQueryParams = () => {
  saveAllQueries()

  document.addEventListener('click', e  => {
    const { target } = e
    if (target.hasAttribute('href')) {
      const savedQueries = getAllCookies()
      const linkMacros = linkHasMacros(target.href)
      if (!!linkMacros) {
        linkMacros.forEach(m => {
          target.search = replaceMacrosKey(target.search, m, savedQueries)
        })
      } else {
        const queries = getQueries(target.search)
        if (queries) {
          target.search = `?${serialize({...queries, ...savedQueries})}`
        }
      }
    }
  })
}
