import { useStaticQuery, graphql } from "gatsby"
import { SiteMetadata } from "../types/QueryResults"

interface QueryResult {
  site: { siteMetadata: SiteMetadata }
}

const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          description
          siteUrl
          title
        }
      }
    }
  `)

  return site.siteMetadata
}

export default useSiteMetadata
