import React, { FunctionComponent } from "react"

interface Props {
  className: string
  title: string,
  style: object
}

const Login: FunctionComponent<Props> = ({ className, title, style }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
    strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
    <title>{title}</title>
    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
    <polyline points="10 17 15 12 10 7" />
    <line x1="15" y1="12" x2="3" y2="12" />
  </svg>
)

export default Login



