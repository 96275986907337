import React, { FunctionComponent, useEffect } from "react"
import Footer from "./Footer"
import Header from "./Header"
import Intercom from "./Intercom"
import { startForwardQueryParams } from "../utils/forward-query-params"

// @ts-ignore
const Layout: FunctionComponent = ({ children, disableIntercom }) => {
  useEffect(() => {
    startForwardQueryParams()
  })

  return (
    <>
      <Intercom disableIntercom={disableIntercom}/>
      <Header />
      <main className="pt-16 xmd:pt-24 overflow-x-hidden">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
