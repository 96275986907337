import React, { FunctionComponent, useEffect, useState } from 'react'
import { Intercom, LiveChatLoaderProvider } from 'react-live-chat-loader'
import { useQueryParam } from 'gatsby-query-params'

// export const IntercomHide: FunctionComponent<any> = () => {
//   useEffect(() => {
//     console.log('IntercomHide')
//     window && window.Intercom && window.Intercom('update', { hide_default_launcher: true })
//     window && window.Intercom && window.Intercom('hide')
//   }, [])
//   return null
// }

const waitFor = timeout => new Promise(resolve => setTimeout(() => resolve(true), timeout))

const waitForIntercom = async (token, isCancelled) => {
  return new Promise(async (resolve) => {

    let cancelled = isCancelled || false

    token.cancel = () => {
      cancelled = true
    }

    if (typeof window !== `undefined`) {
      let intercomLoaded = window && window.Intercom && window.Intercom.booted

      if (intercomLoaded || cancelled) {
        return resolve(!cancelled)
      }

      await waitFor(1000)

      return resolve(await waitForIntercom(token, cancelled))
    }

    return resolve(false)
  })
}

const IntercomBootMasterComponent = ({disableIntercom}) => {
  const disablePopovers = useQueryParam('ifr', undefined)

  if (!!disablePopovers) {
    return null
  }
  if (disableIntercom) {
    if(typeof window !== 'undefined') {
      window && window.Intercom && window.Intercom('update', {hide_default_launcher: true})
      window && window.Intercom && window.Intercom('hide')
    }
    return null
  }
  else {
    return <LiveChatLoaderProvider
      providerKey={'my1xf1yr'}
      provider="intercom"
      onReady={console.log}
      beforeInit={console.log}
    >
      <Intercom color="#00DED1FF" />
    </LiveChatLoaderProvider>
  }
}

export default IntercomBootMasterComponent